body {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  background-color: #ffffff;
  font-family: 'Raleway', sans-serif; /* Match da fonte para todos os textos */
  overflow-x: hidden; /* Evita qualquer margem lateral */
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 1;
  padding: 0; /* Remove padding lateral */
  position: relative;
  box-sizing: border-box; /* Garante que padding não cause overflow */
}

.logo {
  margin-top: 20px;
  max-width: 200px; /* Aumentado para deixar o logo maior */
  z-index: 3; /* Garante que o logo esteja acima de outros elementos */
}

.header {
  font-size: 1.8rem; /* Reduz o tamanho principal */
  font-weight: normal; /* Normal para consistência */
  color: #000; /* Preto */
  margin-bottom: 10px; /* Espaçamento para separar o próximo elemento */
  position: relative;
  z-index: 2; /* Evita sobreposição com Site em Construção */
}

.header span {
  display: block; /* Quebra em uma nova linha */
  font-size: 2.2rem; /* Ligeiramente maior */
  font-weight: bold; /* Destaca Vila Real de Santo António */
  color: #000;
  margin-top: 5px; /* Espaço entre linhas */
}

.message {
  font-size: 2.2rem; /* Tamanho maior para Site em Construção */
  color: #333;
  font-weight: normal; /* Remove bold */
  position: relative; /* Ajuste para o fluxo correto */
  margin: 20px 0; /* Espaçamento entre elementos */
  z-index: 2; /* Alinha corretamente sem sobreposição */
  text-align: center;
}

.contact {
  position: absolute;
  bottom: 80px; /* Ajuste para alinhar com a base das imagens */
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.9rem; /* Ajuste do tamanho */
  color: #333;
  z-index: 2;
}

.contact p {
  margin: 5px 0;
  font-weight: normal; /* Texto normal */
}

.contact p strong {
  font-weight: bold; /* Bold apenas em Seg-Sex e Sab */
}

.contact .email {
  font-size: 0.9rem; /* Ajuste do tamanho para se alinhar com as patas */
  color: #333;
  position: relative;
  bottom: 0; /* Remove espaço extra */
}

.images {
  display: flex;
  justify-content: space-between; /* Espaçamento entre imagens */
  align-items: flex-end; /* Alinha a base das imagens */
  width: 100%;
  max-width: 1200px;
  margin: 0 auto; /* Centraliza o container */
  position: relative;
  z-index: 2; /* Garante que as imagens estejam acima da barra */
  padding-bottom: 0; /* Remove padding extra */
  box-sizing: border-box; /* Evita margens adicionais */
}

.images img {
  flex: 1; /* Distribui espaço proporcional */
  max-height: 300px; /* Ajusta altura máxima */
  width: auto;
  object-fit: contain; /* Mantém proporção */
  margin-bottom: 0; /* Remove espaço extra abaixo das imagens */
}

.footer {
  background-color: rgb(163, 213, 211); /* Cor verde ajustada */
  color: #333;
  text-align: center;
  padding: 10px; /* Espaçamento vertical reduzido */
  font-size: 0.6rem; /* Texto muito menor */
  width: 100%;
  position: relative; /* Parte do fluxo normal da página */
  bottom: 0; /* Elimina espaço branco abaixo */
  left: 0;
  z-index: 0; /* Mantém abaixo das imagens */
  margin: 0;
  box-sizing: border-box;
}

body::after {
  display: none; /* Remove completamente a pseudo-classe que cria a barra adicional */
}
